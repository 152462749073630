import React from 'react'

import Container from './styles'
import { Button } from 'components/Button'

export default ({ history }) => {
  const probability = window.result['probability']
  const result = window.result['live']
  const image = window.result['screenshot']

  const handleRestart = () => {
    history.replace('/token')
  }

  return (
    <React.Fragment>
      <h1>Resultado</h1>
      <Container>
        <div className="body">
          <React.Fragment>
            <div className="screenshotContainer">
              <img className="screenshot" src={image} alt="" />
            </div>
            <div className="results">
              <div className="progressBar">
                <div
                  className={`progressValue ${
                    result === true  ? 'approvedBack' : 'reprovedBack'
                  }`}
                  style={{ width: `${probability}%` }}
                />
              </div>
              <div
                className={`result ${
                  result === true ? 'approved' : 'reproved'
                } `}
              >
                <p>{result === true  ? 'Aprovado' : 'Reprovado'}</p>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className="buttonContainer">
          <Button onClick={handleRestart}>Reiniciar</Button>
        </div>
      </Container>
    </React.Fragment>
  )
}
