import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import Webcam from 'react-webcam'
import CircularProgress from '@material-ui/core/CircularProgress'

import Container from './styles'
import { image64toCanvasRef } from 'utils/imageFns'
import { Store } from 'store'
import { newPostFile } from 'api/api'
import { SET_IMAGE } from 'store/reducers/imageReducer'

export default function ({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [detectMessage, setDetectMessage] = useState(
    'Iniciando detecção facial',
  )
  const [loading, setLoading] = useState(false)
  const [_, dispatch] = useContext(Store)
  const [cameraStarted, setCameraStarted] = useState(false)
  const [validFace, setValidFace] = useState(false)
  let retry = 0

  const getFrame = useCallback(
    async (screenshot, faceBox=null) => {
      const canvas = canvasRef.current
      let formData = new FormData()
      let file = null

      await image64toCanvasRef(canvasRef.current, screenshot, faceBox)
      const croppedImage = canvas.toDataURL('image/jpeg', 0.9)

      dispatch({ type: SET_IMAGE, payload: screenshot })

      await fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], `liveness_photo.jpeg`, {
            type: 'image/jpeg',
          })
          formData.append('frame', file)
        })

      if (retry === 2) {
        setLoading(true)
      }

      const res = await newPostFile({
        url: '/predict',
        token: 'c2F1bG86Tl5EQmFeMm1hcnFvJmJJV1BHMzF6d1lvZTR2NWhr',
        data: formData,
        history,
      })

      if (res) {
        if (!res.live && retry < 2) {
          retry += 1
          return false
        } else {
          window.result = {
            'screenshot': screenshot,
            'live': res.live,
            'probability': res.probability
          }

          return history.push('/evaluation')
        }
      }
    },
    [history],
  )

  const findFace = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      if (!cameraRef.current) {
        resolve(false)
      }
      
      const screenshot = cameraRef.current.getScreenshot()

      if (screenshot) {
        const res = await getFrame(screenshot, cameraRef.current.props)

        if (!res) {
          reject(false)
        } else {
          resolve(true)
        }
      } else {
        reject(false)
      }
    })
  }, [getFrame])

  const main = useCallback(async () => {
    setDetectMessage('Enquadre seu rosto no meio...')
    setLoading(false)
    setValidFace(false)

    if (cameraRef.current && cameraRef.current.getScreenshot()) {
      setValidFace(true)

      setTimeout(async () => {
        findFace().catch(() => {
          setLoading(false)
          main()
        })
      }, 1800)
    } else {
      setLoading(false)
      setValidFace(false)
      setTimeout(main, 300)
    }
  }, [findFace])

  useEffect(() => {
    main()
  }, [main])

  const handleCameraStart = () => {
    setCameraStarted(true)
  }

  return (
    <Container>
      <div className="cameraContainer">
        {cameraStarted && (
          <React.Fragment>
            <div className={`cameraOverlay ${!detectMessage && 'off'}`}>
              <span>{detectMessage}</span>
            </div>
            <div className={`loadingOverlay ${loading && 'on'}`}>
              <CircularProgress className="loadingLoader" />
            </div>
            <div className={`mask ${validFace ? 'valid' : ''}`} />
          </React.Fragment>
        )}
        <Webcam
          audio={false}
          ref={cameraRef}
          minScreenshotHeight={700}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: 'user' }}
          onUserMedia={handleCameraStart}
        />
        <canvas ref={canvasRef} id="canvas"></canvas>
      </div>
    </Container>
  )
}
